import '../App.css';
import ReactDOM from 'react-dom';
//import JDUploadModal from '../modals/JDUploadModal';
import ResumeUploadModal from '../modals/ResumeUploadModal';
import React, {useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import LinkedInModal from '../modals/LinkedInModal';
import JobPresetForm from '../modals/JobPresetEditModal.js';
import { Navbar, Container, Image, Form, FormControl, DropdownToggle, DropdownMenu} from 'react-bootstrap';
import CreospanLogo from '../assets/creospanLogo.png';
import NotificationIcon from '../assets/NotificationIcon.png';
import Profile from '../assets/Ravi.jpeg'
import Dropdown from 'react-bootstrap/Dropdown';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import ChoosePresetModal from '../modals/ChoosePresetModal.js'
// Side Bar Icons
import { DashboardIcon } from '../assets/icons/dashboard.tsx';
import { GenerateIcon } from '../assets/icons/generate.tsx';
import { PastInterviewIcon } from '../assets/icons/pastinterview.tsx';
import { JobDescriptionIcon} from '../assets/icons/jobdescription.tsx';
import { CandidateIcon } from '../assets/icons/candidateprofile.tsx';
import { MembersIcon } from '../assets/icons/members.tsx';
import { SettingsIcon} from '../assets/icons/settings.tsx';
import { BackArrow } from '../assets/icons/backarrow.tsx';

const backend_key = process.env.REACT_APP_BACKEND_API;

const NewInterview = (args) => {
//  const [DescriptionUploaded, SetDescriptionUpload ] = useState(false);
    const [ResumeUploaded, SetResumeUploaded] = useState(false);
    const [JDText, SetJDText] = useState('');
    const [CRText, SetCRText] = useState('');
    const [DDText, SetDDText] = useState('Select Job');
    const [Preset, SetGrabPreset] = useState([null])
    const [DetectPresetOpen, setPresetOpen] = useState(false)
    const [Linkedin_ID, SetLinkedIn] = useState(null);
    const isAdmin = args.isAdmin()

    


    var sdBar = ""
    if (!isAdmin){
        sdBar = "grey"
    }
    
   const changeDDValue = (title) => SetDDText(title) 
 

    const handleFileSubmission = () => {
        if(( JDText || (DDText != 'Select Job')) && (ResumeUploaded || CRText)){
           
            

            console.log(DDText)
            args.setLoading(true);
            args.setAIGenLoading(true);
            args.setDownloadShown(true)
            args.setViewDash(false);
            
            const fileForm = new FormData();
            if(DDText == Preset.Job_Title){
                fileForm.append('jobDescription', Preset.Job_Title + ' - ' + Preset.Job_Description)
            }else{
                DDText ? fileForm.append('jobDescription', DDText) : fileForm.append('jobDescription', JDText);
            }
            ResumeUploaded ? fileForm.append('resumeUpload', ResumeUploaded) : fileForm.append('resumeUpload', CRText);
            if (Linkedin_ID)
                fileForm.append('linkedinProfile', Linkedin_ID)

            let errorData;
        
            fetch('/uploadQA', {
                method: 'POST',
                headers: {
                    'my-custom-header-key': backend_key,
                },
                credentials: 'include',
                body: fileForm,
            })
            .then((response) => response.json())
            .then(async (data) => {
                errorData = data;
                let missing_qualities = '';
                for(let quality of data[2]){
                    missing_qualities += '- ' + quality + '\n\n'
                }
                let general_qs = [];
                let technical_qs = [];
                if(data[0] && data[1]) {
                    for(let q of data[0]){
                        general_qs.push({...q, notes: ''})
                    }
                    for(let q of data[1]){
                        technical_qs.push({...q, notes: ''})
                    }
                }
                let saveData = {
                    uuid: uuidv4(),
                    date: getCurrentDate(),
                    Candidate_Name: data[5].Candidate_Name || 'NOT FOUND' ,
                    Job_Title: data[5].Job_Title || 'NOT FOUND',
                    Job_Description_Title: data[5].Job_Description_Title || 'NOT FOUND',
                    Linkedin_ID: data[5].Linkedin_ID || 'NOT FOUND',
                    general_questions: general_qs || [],
                    technical_questions: technical_qs || [],
                    lacking_qualities: missing_qualities.trim(),
                    strengths: data[6].trim(),
                    score: data[3],
                    assessment: data[4],
                    overall_notes: ''
                };
                let linkedinAssess = await fetchLinkedinAssessment(saveData.Linkedin_ID);
                saveData = {...saveData, linkedCompare: linkedinAssess}
              //  let imageData = await getImagefromResume()
                
               // saveData = {...saveData, imageSRC: imageData}
                args.handleSaveCandidate(saveData);
                args.setCandidateInfo(saveData);
                args.setNewInterview(false);
                args.setAIGenLoading(false);
             
                args.setLoading(false);
                
            })
            .catch((error) => {
                console.error(error);
                alert("Issue creating Interview. Resume May be too long. Check logs.")
                console.log(errorData);
            });
        }
    }

    const getImagefromResume = async () =>{
        
        const fileForm = new FormData();
        ResumeUploaded ? fileForm.append('resumeUpload', ResumeUploaded) : fileForm.append('resumeUpload', CRText);


        let response = await fetch('/getImage', {
            method: 'POST',
            headers: {
                'my-custom-header-key': backend_key,
            },
            credentials: 'include',
            body: fileForm,
        })
        let data = await response.json();
        
        
        if(data.status == 500){
            alert(data.error.message)
            return ''
        }
        if(data.image){
            const imageSRC = "data:image/png;base64," + data.image;
            args.setImageFound(true)
            return imageSRC
        }
        else
            return ''
        
    }

    const getCurrentDate = () => {
        let date = new Date();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let year = date.getFullYear();
        return `${month}/${day}/${year}`
    }


    
    const fetchLinkedinAssessment = async (linkedinID) => {
      
        if(linkedinID !== 'NOT FOUND'){
            const fileForm = new FormData();
            ResumeUploaded ? fileForm.append('resumeUpload', ResumeUploaded) : fileForm.append('resumeUpload', CRText);
            fileForm.append('linkedinProfile', linkedinID);
    
            let response = await fetch('/assessLinkedin', {
                method: 'POST',
                headers: {
                    'my-custom-header-key': backend_key,
                },
                credentials: 'include',
                body: fileForm,
            })
            let data = await response.json();
            if(data.error){
                alert(data.error.message)
                return ''
            }
            if(data.invalid){
                alert(data.invalid)
                return ''
            }
            return data.assessment
        } else {
            let profileId = await getProfileWithModal();
            if(profileId === ''){
                return ''
            } else {
                const fileForm = new FormData();
                ResumeUploaded ? fileForm.append('resumeUpload', ResumeUploaded) : fileForm.append('resumeUpload', CRText);
                fileForm.append('linkedinProfile', profileId);
                let response = await fetch('/assessLinkedin', {
                    method: 'POST',
                    headers: {
                        'my-custom-header-key': backend_key,
                    },
                    credentials: 'include',
                    body: fileForm,
                })
                let data = await response.json();
                if(data.error){
                    alert(data.error.message)
                    return ''
                }
                if(data.invalid){
                    alert(data.invalid)
                    return ''
                }
                return data.assessment
            }
        }
    }


    function getProfileWithModal() {
        return new Promise((resolve) => {
            const usernameInputModal = (
                <LinkedInModal
                    onClose={(profileId) => {
                        resolve(profileId);
                        closeModal();
                    }}
                />
            );
            ReactDOM.render(usernameInputModal, document.getElementById('modal-root'));
        });
    }

    function closeModal() {
        ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'));
    }

    

    const pastInterviewsPage = () => {
        args.setNewInterview(false)
        args.loadDB((!args.viewDB), (args.viewDash))
    
    }

    const new_Interviews_Page = () =>{
        args.setNewInterview(false)
        args.setNewInterview(true)
       
    } 

    const Dashboard_Page = () =>{
        args.setNewInterview(false)
        args.setJobDescPage(false)
        args.setViewDB(false)
        args.setViewDash(true)
    }
    const Job_Description_Page = () =>{
        if(isAdmin==true){
        args.setNewInterview(false)
        args.setViewDB(false)
        args.setJobDescPage(true)
        args.ADDNEW(true)
        args.setFileClicked(false)
     
        }
    }
    
   
   
    
    return (
        <>  
            


             <Navbar className="navbar justify-content-between" style={{"height": "60px", padding:"0px"}}>  
               
                    
                    <Navbar.Brand href="#" style={{display:'flex', alignItems:'center'}} > 
                    <Image src={CreospanLogo} roundedCircle width="60" height="60" />
                    </Navbar.Brand>
                   
                    
                   <Container className='d-flex flex-row-reverse'>
                    <Form className='d-flex flex-row-reverse' style={{borderRadius:"50px"}}>
                       <FormControl type='search' placeholder='🔍 Search' style={{display:'flex', alignItems:'center', backgroundColor:'#e49d60', border:'#e08537', paddingRight:"210px", color:'white'}}></FormControl>
                    </Form>
                    </Container>
                  
                  
                <Navbar.Brand href='#'>
                    <Image src={NotificationIcon} roundedCircle width="30" height="30"></Image>
                </Navbar.Brand>
                
                <Navbar.Brand href ='#'>
                    <Image src={Profile} roundedCircle width="40" height="40"></Image>
                </Navbar.Brand>
                 
             </Navbar>
            
            <div className='row main' style={{"marginLeft":'0px',"flex":"1","--bs-gutter-x":"0"}}>
           
            
            <Sidebar style={{"width":"280px",'color':'orange', height:'100vh'}}>
                <Menu iconShape="square" style={{"margin-top":"40%", 'background-color':'white', border: 'white' }}>
                    <MenuItem onClick={Dashboard_Page} icon={<DashboardIcon/>} style={{paddingLeft:'1%'}}><div style={{marginLeft:'3%', marginTop:'5%'}}> Dashboard </div> </MenuItem>
                    <MenuItem onClick={new_Interviews_Page} icon={<GenerateIcon/>} style={{paddingLeft:'3%', marginTop:'1%'}}> Generate New Interviews </MenuItem>
                    <MenuItem onClick={pastInterviewsPage} icon={<PastInterviewIcon/>} style={{paddingLeft:'3%'}}> Past Interviews </MenuItem>
                    <MenuItem onClick={Job_Description_Page} icon={<JobDescriptionIcon/>} style={{paddingLeft:'3%', marginTop:'2%', backgroundColor:sdBar}}> Job Description File </MenuItem>
                    <MenuItem icon={<CandidateIcon/>} style={{paddingLeft:'3%'}}> Candidates Profile </MenuItem>
                    <MenuItem icon={<MembersIcon/>} style={{paddingLeft:'3%'}}> Members </MenuItem>
                    <MenuItem icon={<SettingsIcon/>} style={{paddingLeft:'3%'}}> Settings </MenuItem>
                </Menu>
            </Sidebar>
           
            
            
             

              
               
                        <div className='side'>
                      
                        <h1 style={{color: "black", "font-weight": "bold", 'marginLeft': '17%', "font-size": "19px", 'marginTop':'4%', 'marginBottom':'2%'}}>Job Description</h1>
                       
                        <Dropdown style={{marginLeft:"17%", width:'50%', 'box-shadow': 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'}}>
                        
                        <DropdownToggle variant ="success" style={{width:"100%", borderRadius:'inherit', color:'black', 'border-block-color': 'white', backgroundColor: 'white', border:'white', paddingLeft:'5%', 'font-family':'sans-serif' }} id='dropdown-basic'>{DDText}</DropdownToggle>
                      
                        <DropdownMenu>
                            <Dropdown.Item as = "button"><div onClick={(e) =>changeDDValue(e.target.textContent)} >EE Hardware Engineer</div></Dropdown.Item>
                            <Dropdown.Item as = "button"><div onClick={(e) =>changeDDValue(e.target.textContent)} >DevOps Automation Engineer</div></Dropdown.Item>
                            <Dropdown.Item as = "button"><div onClick={(e) =>changeDDValue(e.target.textContent)} >Microsoft Azure Subject Matter Expert (SME)</div></Dropdown.Item>
                            <Dropdown.Item as = "button"><div onClick={(e) =>changeDDValue(e.target.textContent)} >Software Engineer - AR/VR Graphics</div></Dropdown.Item>  
                            <Dropdown.Item as = "button" className="btn btn-secondary p-3" data-bs-toggle="modal" data-bs-target="#choosePresetModal" onClick={() => setPresetOpen(true)} >Select Preset</Dropdown.Item>  
                           
                        
                        </DropdownMenu>
                        
                        </Dropdown>
                     
                        <button className='fixed-bottom ' style={{"margin": "20px 0 20px 20px", "width":"80px", border:'none', backgroundColor:'white'}} onClick={() => Dashboard_Page()} ><BackArrow></BackArrow></button>
                        <div>
                            
                            <p style={{color: "#897f7f", 'marginLeft': '17%', "font-size": "14px", 'marginTop':'4%' , marginBottom:'0px'}}>Paste Job Title and Description Text Below:</p>
                            <textarea className='JDTextArea' value={JDText} onChange={(event) => SetJDText(event.target.value)}></textarea>
                        </div>
                    
                        <div className='row'>
                        <h1 style={{color: "black", "font-weight": "bold", 'marginLeft': '17%', "font-size": "19px", 'marginTop':'3%', 'padding-left':'0.5%'}}>Candidate Resume</h1>
                        
                        
                       
                        
                        <div className='row'>
                           
                            <p style={{color: "#897f7f", 'marginLeft': '17%', "font-size": "14px", marginBottom:'0px', 'padding-left':'inherit'}}>Paste Resume Text Below:</p>
                            <textarea className='JDTextArea' style={{marginLeft:'18%', width:'50%'}} value={CRText} onChange={(event) => SetCRText(event.target.value)}></textarea>
                        
                        <div style={{width:'13%'}}>
                        <h4 style={{color: '#897f7f', 'marginLeft': '50%', fontSize:'14px', marginTop:'10%'}}> OR </h4>
                        </div>
                        
                        <div style={{width:'13%'}}>
                            { ResumeUploaded ? (
                                <button type="button" className="btn btn-secondary p-3" data-bs-toggle="modal" data-bs-target="#selectFileModalResume">{ResumeUploaded.name}</button>
                            ):(
                                <button type="button" className="uploadBtn " data-bs-toggle="modal" data-bs-target="#selectFileModalResume">Upload</button>
                            )}
                        </div>

                        </div>
                        
                       
                </div>
                
                <div className='row'>
                
                <h1 style={{color: "black", "font-weight": "bold", 'marginLeft': '17%', "font-size": "19px", 'marginTop':'3%', 'padding-left':'0.5%'}}>LinkedIn Profile</h1>
                <p style={{color: "#897f7f", 'marginLeft': '17.5%', "font-size": "14px", marginBottom:'0px', 'padding-left':'inherit'}}>Example: Matt-Drong</p>
                <textarea className='JDTextArea' style={{ width:'49%', marginLeft:'17.5%', height:'102px'}} value={Linkedin_ID} onChange={(event) => SetLinkedIn(event.target.value)}></textarea>
                
                <div style={{width:'13%', marginLeft:'12.7%'}}>
                    <button style={{'background-color':'#e08537', 'border':'#e08537'}} type="button" className='uploadBtn' onClick={handleFileSubmission}>Submit</button>
                </div>
                </div>

                </div>
               
                </div>
            

            <ChoosePresetModal SetGrabPreset = {SetGrabPreset} presetData ={args.presetData} isOpen={DetectPresetOpen} setPresetOpen ={setPresetOpen} SetDDText ={SetDDText} />
            <ResumeUploadModal SetResumeUploaded={SetResumeUploaded}/>
        </>
    )

}

export default NewInterview;