import './App.css';
import React, {useState} from 'react';
import DisplayInterview from './pages/DisplayInterview';
import JobDescriptionPage from './pages/JobDescription.js';
import InterviewDB from './pages/InterviewDB';
import NewInterview from './pages/NewInterview';
import Loading from './pages/Loading';
import Dashboard from './pages/Dashboard';
import { getUserRole, getUserName } from './index.js';

const backend_key = process.env.REACT_APP_BACKEND_API;

/**
 * Component for managing the InterviewGPT functionality.
 *
 * @returns {JSX.Element} The InterviewGPT component.
 */
const InterviewGPT = ({user}) => {
	/**
	 * All these state variables are used in AT LEAST 2 jsx components and so they are defined here and passed through args
	 * At a later point, the code should be split up further. Currently, nearly all jsx "components" are just entire pages. 
	 * 
	 * Various boolean state variables to control the flow of the application (which page to display when a specific event occurs).
	 * Can be tricky to follow so may be refactored later.
	*/
	
	const [loading, setLoading] = useState(false);
	const [AIGenLoading, setAIGenLoading] = useState(false);
	const [viewDB, setViewDB] = useState(false);
	const [newInterview, setNewInterview] = useState(false);
	const [viewDash, setDashboard] = useState(true)
	const [JobDescPage, setJobDescPage] = useState(false)
	const [fileClicked, setFileClicked] = useState(false)
	const [ADDNEWClicked, setAddNewClicked] = useState(true)
	const [pendingClicked, setPendingClicked] = useState(false)
	// A single interview which is passed to DisplayInterview component to display the generated data and notes page
	const [candidateInfo, setCandidateInfo] = useState();

	// State variable containing all of the interviews previously generated, gotten from the db to be displayed in the db user interface page 
	const [interviewsData, setInterviewsData] = useState([]);
	const [presetData, setPresetData] = useState([])
	const [unofficialData, setUNData] = useState([])
	const [showDownloadButton, setDownloadShown] = useState(false)
	const [imageFound, setImageFound] = useState(false)
	const [powerAdmin, setPowerAdmin] = useState(false)

	function checkRole(){
		const userRole = getUserRole()
		if (userRole == "PowerUser"){
			return true
		} else if(userRole == "PowerAdmin"){
			setPowerAdmin(true)
			return true
		}
		else{
			return false
		}
	}

	async function loadDB(viewDB, dashboard){
		return new Promise((resolve, reject) => {
        setLoading(true);
		console.log(viewDB, dashboard)
		setDashboard(!dashboard)
    
        fetch('/getAll', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include'
		})
        .then((response) => response.json())
        .then((data) => {
			
            setInterviewsData(data || []);
			
			console.log(data || [])
            setViewDB(viewDB);
			setDashboard(dashboard)
            setLoading(false);
			console.log("Database Loaded! (:")
			
			
			setTimeout(() =>{
				resolve(data || [])
				
				
			}, 2000)
			
			
		})
			
		
		})
        .catch((error) => {
			alert("Trouble retrieving Data. Server May be down. Check Logs");
            console.error('Error:', error);
        });
	
    }

	async function loadPresetsDB(){
		return new Promise((resolve, reject) => {
		setAddNewClicked(false)
		setJobDescPage(false)
		setDashboard(false)
        setLoading(true);
    
        fetch('/getAllPresets', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include'
		})
        .then((response) => response.json())
        .then((data) => {
			
            setPresetData(data || []);
			
			console.log(data || [])
			setDashboard(true)
			setJobDescPage(true)
		//	setFileClicked(true)
			setAddNewClicked(false)
            setLoading(false);
		
			
			
			console.log(" JD Database Loaded! O:")
			
			
			setTimeout(() =>{
				resolve(data || [])
		
				
			}, 2000)
			
			
		})
			
		
		})
        .catch((error) => {
			alert("Trouble retrieving JD-Data. Server May be down. Check Logs");
            console.error('Error:', error);
        });
	
    }


	async function loadUN_DB(){
		const user = getUserName()
		return new Promise((resolve, reject) => {
		setAddNewClicked(false)
		setJobDescPage(false)
		setDashboard(false)
        setLoading(true);
    
        fetch('/getAllUserPresets', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
			body: JSON.stringify({username : user})
		})
        .then((response) => response.json())
        .then((data) => {
			
            setUNData(data || []);
			
			console.log(data || [])
			setDashboard(true)
			setJobDescPage(true)
			//setFileClicked(true)
			setAddNewClicked(false)
            setLoading(false);
		
			
			
			console.log(" UN Database Loaded! /:")
			
			
			setTimeout(() =>{
				resolve(data || [])
		
				
			}, 2000)
			
			
		})
			
		
		})
        .catch((error) => {
			alert("Trouble retrieving JD-Data. Server May be down. Check Logs");
            console.error('Error:', error);
        });
	
    }


	const handleSaveCandidate = (saveData) => {
		fetch('/saveCandidate', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
			body: JSON.stringify({...saveData, user:user}),
		})
		.then((response) => response.json())
		.then((data) => {
			console.log(data);
		})
		.catch((error) => {
			alert("Trouble Saving Data. Check Logs.")
			console.error('Error:', error);
		});
    }

	const handleSavePreset = (saveData) => {
		console.log(saveData)
		fetch('/saveJD', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
			body: JSON.stringify({...saveData}),
			
		})
		.then((response) => response.json())
		.then((data) => {
			console.log(data);
		})
		.catch((error) => {
			alert("Trouble Saving Data. Check Logs.")
			console.error('Error:', error);
		});
    }

	const handleSaveUN = (saveData) => {
		console.log(saveData)
		fetch('/saveUN', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
			body: JSON.stringify({...saveData}),
			
		})
		.then((response) => response.json())
		.then((data) => {
			console.log(data);
		})
		.catch((error) => {
			alert("Trouble Saving Data. Check Logs.")
			console.error('Error:', error);
		});
    }

	return (
		<>
			{!viewDash ? (
				<>
					{loading ? (
						<Loading AIGenLoading={AIGenLoading}/>
					): (
						<DisplayInterview candidateInfo={candidateInfo} setCandidateInfo={setCandidateInfo} handleSaveCandidate={handleSaveCandidate} setViewDash = {setDashboard} setNewInterview={setNewInterview} loadDB={loadDB} setViewDB={setViewDB} interviewsData={interviewsData} showDownloadButton = {showDownloadButton} setDownloadShown = {setDownloadShown}  viewDB={viewDB} viewDash={viewDash} setLoading={setLoading} isLoading ={loading} imageFound={imageFound} setJobDescPage = {setJobDescPage} />
					)}
				</>
			): (
				<>
					{newInterview ? (
						<>
							
							<NewInterview setAIGenLoading={setAIGenLoading} setLoading={setLoading} setCandidateInfo={setCandidateInfo} setNewInterview={setNewInterview} setViewDB={setViewDB} loadDB={loadDB} handleSaveCandidate={handleSaveCandidate} setViewDash = {setDashboard} setDownloadShown = {setDownloadShown}  viewDB={viewDB} viewDash={viewDash} setImageFound={setImageFound} setJobDescPage = {setJobDescPage} isAdmin ={checkRole} presetData ={presetData} loadPresetsDB = {loadPresetsDB}  ADDNEW ={setAddNewClicked} setFileClicked ={setFileClicked} />
						</>
					):(
						viewDB ? (
							<>
								<InterviewDB loadDB={loadDB} interviewsData={interviewsData} setLoading={setLoading} setViewDB={setViewDB} viewDash={viewDash} viewDB ={viewDB} setCandidateInfo={setCandidateInfo}  setViewDash = {setDashboard } setJobDescPage = {setJobDescPage} powerAdmin ={powerAdmin}/>
							</>
						
						):(
						JobDescPage ? (
							<>
							<JobDescriptionPage setViewDB={setViewDB} viewDB={viewDB} viewDash={viewDash} loadDB={loadDB}  setNewInterview={setNewInterview} setViewDash = {setDashboard} setDownloadShown = {setDownloadShown} isAdmin ={checkRole} setJobDescPage = {setJobDescPage} presetData ={presetData}  UNData ={unofficialData}  loadPresetsDB = {loadPresetsDB} loadUN_DB ={loadUN_DB} handleSavePreset = {handleSavePreset} handleSaveUN ={handleSaveUN} setFileClicked ={setFileClicked} fileClicked ={fileClicked} setADDNEW ={setAddNewClicked} ADDNEWClicked= {ADDNEWClicked} setLoading={setLoading}  setOffPresetData={setPresetData} setUNPresetData ={setUNData} setLoadJD ={setJobDescPage} powerAdmin ={powerAdmin} pendingClicked ={pendingClicked} setPendingClicked ={setPendingClicked} />
							</>
						):(
							<>
							<Dashboard setViewDB={setViewDB} viewDB={viewDB} viewDash={viewDash} loadDB={loadDB}  setNewInterview={setNewInterview} setViewDash = {setDashboard} setDownloadShown = {setDownloadShown} isAdmin ={checkRole} setJobDescPage = {setJobDescPage} loadPresetsDB = {loadPresetsDB} ADDNEW ={setAddNewClicked}  setFileClicked ={setFileClicked}   />
							</>
						)
					)
					)
					
					}
				</>
			)}
			<div id='modal-root'></div>
		</>
	);
}

export default InterviewGPT;